<!-- Contact -->
<section class="contact mt-100 pb-100" data-scroll-index="5">
  <div class="container">
    <div class="row text-center">
      <div class="col-md-12 col-12 col-sm-12 mb-20">
        <h6 class="small-title">Get In Touch</h6>
        <h4 class="title">Contact Us</h4>
      </div>
    </div>
    <div class="row">
      <!-- Map -->
      <div id="map" class="col-md-8">
        <a href="https://goo.gl/maps/gzaavfkHi7C9n2uP9" target="_blank">
          <img src="/assets/images/map-snapshot.png" alt="Map Link" />
        </a>
      </div>

      <div class="col-md-4 col-12 col-sm-12 col-lg-4">
        <div class="item bg-4">
          <div class="con">
            <h5>KUMBAKONAM</h5>
            <p>
              <i class="ti-home" style="font-size: 15px; color: #c5a47e;"></i>
              34, Niyas Complex, 4 Road, <br />
              Kumbakonam, Tamil Nadu - 612001
            </p>
            <p>
              <i class="ti-mobile" style="font-size: 15px; color: #c5a47e;"></i>
              <a href="tel:+91 85259 64904">+91 85259 64904</a>
            </p>
            <p>
              <i
                class="ti-envelope"
                style="font-size: 15px; color: #c5a47e;"
              ></i>
              <a href="mailto:info@edgedesign.co.in">info@edgedesign.co.in</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-20">
      <div class="offset-md-3 col-md-6 col-12">
        <form class="form" id="contact-form">
          <div class="messages"></div>
          <div class="controls">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group has-error has-danger">
                  <input
                    id="form_name"
                    type="text"
                    name="name"
                    placeholder="Name"
                    required="required"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group has-error has-danger">
                  <input
                    id="form_email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    required="required"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    id="form_subject"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <textarea
                    id="form_message"
                    name="message"
                    placeholder="Message"
                    rows="4"
                    required="required"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <button type="submit" class="btn">
                  <span>Send Message <i class="ti-arrow-right"></i></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<script src="http://maps.googleapis.com/maps/api/js?key=API-KEY"></script>
