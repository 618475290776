<!-- Projects -->
<section class="projects mt-100 pb-100" data-scroll-index="3">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-20 text-center">
        <h6 class="small-title">Portfolio</h6>
        <h4 class="title">Our Projects</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="item mb-50 col-md-4 col-sm-6 col-12 col-lg-4">
            <div class="position-re o-hidden">
              <img
                src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/projects/3.jpg"
                alt=""
              />
            </div>
            <div class="con">
              <span class="category">
                <a href="project.html">Interior</a>
              </span>
              <h5><a href="project.html">Art Museum, USA</a></h5>
              <a href="project.html"><i class="ti-arrow-right"></i></a>
            </div>
          </div>
          <div class="item mb-50 col-md-4 col-sm-6 col-12 col-lg-4">
            <div class="position-re o-hidden">
              <img
                src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/projects/2.jpg"
                alt=""
              />
            </div>
            <div class="con">
              <span class="category">
                <a href="project.html">Architecture</a>
              </span>
              <h5><a href="project.html">Drana Villa, CA</a></h5>
              <a href="project.html"><i class="ti-arrow-right"></i></a>
            </div>
          </div>
          <div class="item mb-50 col-md-4 col-sm-6 col-12 col-lg-4">
            <div class="position-re o-hidden">
              <img
                src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/projects/4.jpg"
                alt=""
              />
            </div>
            <div class="con">
              <span class="category">
                <a href="project.html">Design</a>
              </span>
              <h5><a href="project.html">Home Design, USA</a></h5>
              <a href="project.html"><i class="ti-arrow-right"></i></a>
            </div>
          </div>
          <!-- <div class="item mb-50 col-md-4">
            <div class="position-re o-hidden">
              <img
                src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/projects/1.jpg"
                alt=""
              />
            </div>
            <div class="con">
              <span class="category">
                <a href="project.html">Interior</a>
              </span>
              <h5><a href="project.html">House Office, CA</a></h5>
              <a href="project.html"><i class="ti-arrow-right"></i></a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
