import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // function initMap() {
    //   // The location of Uluru
    //   var uluru = { lat: 10.9645316, lng: 79.3926187 };
    //   // The map, centered at Uluru
    //   var map = new google.maps.Map(document.getElementById('map'), {
    //     zoom: 4,
    //     center: uluru,
    //   });
    //   // The marker, positioned at Uluru
    //   var marker = new google.maps.Marker({ position: uluru, map: map });
    // }
  }
}
