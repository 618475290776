<!-- Our Team -->
<section class="team mt-100 pb-100">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-20 text-center">
        <h6 class="small-title">Professional</h6>
        <h4 class="title">Our Team</h4>
      </div>
      <div class="col-md-10 offset-md-1">
        <div class="owl-carousel owl-theme text-center">
          <div class="item">
            <div class="team-img">
              <img
                src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/team/1.jpg"
                alt=""
              />
              <div class="info valign">
                <div class="text-center full-width">
                  <div class="social">
                    <a href="#" class="icon"> <i class="ti-facebook"></i> </a>
                    <a href="#" class="icon"> <i class="ti-twitter"></i> </a>
                    <a href="#" class="icon"> <i class="ti-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>
            <h6>Matthew Adam</h6>
            <span>Managing Director</span>
          </div>
          <div class="item">
            <div class="team-img">
              <img
                src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/team/2.jpg"
                alt=""
              />
              <div class="info valign">
                <div class="text-center full-width">
                  <div class="social">
                    <a href="#" class="icon"> <i class="ti-facebook"></i> </a>
                    <a href="#" class="icon"> <i class="ti-twitter"></i> </a>
                    <a href="#" class="icon"> <i class="ti-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>
            <h6>Linda Brown</h6>
            <span>Creative Director</span>
          </div>
          <div class="item">
            <div class="team-img">
              <img
                src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/team/3.jpg"
                alt=""
              />
              <div class="info valign">
                <div class="text-center full-width">
                  <div class="social">
                    <a href="#" class="icon"> <i class="ti-facebook"></i> </a>
                    <a href="#" class="icon"> <i class="ti-twitter"></i> </a>
                    <a href="#" class="icon"> <i class="ti-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>
            <h6>Heidi Emma</h6>
            <span>Project Manager</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
