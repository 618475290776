<!-- Blog -->
<section class="blog mt-100 pb-100" data-scroll-index="4">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-20">
        <h6 class="small-title">Our Blog</h6>
        <h4 class="title">Latest News</h4>
      </div>
      <div class="col-md-6">
        <div class="item">
          <div class="post-img">
            <div class="img">
              <a href="blog.html"
                ><img
                  src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/blog/1.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div class="cont">
            <h6>
              <a href="post.html">Trending in Modern Architecture 2020</a>
            </h6>
            <div class="info">
              <a href="blog.html"><b>Event</b> Dec 28, 2020 </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="item">
          <div class="post-img">
            <div class="img">
              <a href="blog.html"
                ><img
                  src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/blog/2.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div class="cont">
            <h6>
              <a href="blog.html">Renovation Architecture and Design</a>
            </h6>
            <div class="info">
              <a href="blog.html"><b>Discover</b> Dec 26, 2020 </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
