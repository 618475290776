<!-- Clients -->
<div class="clients text-center">
  <div class="container">
    <div class="row">
      <div class="col-md-12 owl-carousel owl-theme">
        <div class="client-logo">
          <a href="#"
            ><img
              src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/clients/1.jpg"
              alt=""
          /></a>
        </div>
        <div class="client-logo">
          <a href="#"
            ><img
              src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/clients/2.jpg"
              alt=""
          /></a>
        </div>
        <div class="client-logo">
          <a href="#"
            ><img
              src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/clients/3.jpg"
              alt=""
          /></a>
        </div>
        <div class="client-logo">
          <a href="#"
            ><img
              src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/clients/4.jpg"
              alt=""
          /></a>
        </div>
        <div class="client-logo">
          <a href="#"
            ><img
              src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/clients/5.jpg"
              alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</div>
