<!-- Header Slider -->
<header class="header pos-re slider-fade" data-scroll-index="0">
  <div class="owl-carousel owl-theme">
    <div
      class="item bg-img"
      data-overlay-dark="3"
      style="background-image: url(assets/images/bedroom.jpg);"
    >
      <div class="container text-center v-middle caption">
        <h4>RESIDENTAL</h4>
        <h1>DRANA HOUSE, USA</h1>
        <a href="#" class="btn"><span>Discover More</span></a>
      </div>
    </div>
    <div
      class="item d-none bg-img"
      data-overlay-dark="3"
      data-background="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/slider/2.jpg"
    >
      <div class="container text-center v-middle caption">
        <h4>ARCHITECTURE</h4>
        <h1>ARCH STUDIO, CA</h1>
        <a href="#" class="btn"
          ><span>Discover More <i class="ti-arrow-right"></i></span
        ></a>
      </div>
    </div>
    <div
      class="item d-none bg-img"
      data-overlay-dark="3"
      data-background="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/slider/3.jpg"
    >
      <div class="container text-center v-middle caption">
        <h4>ARCHITECTURE</h4>
        <h1>ART MUSEUM, USA</h1>
        <a href="#" class="btn"
          ><span>Discover More <i class="ti-arrow-right"></i></span
        ></a>
      </div>
    </div>
  </div>
</header>
