<!-- Footer -->
<footer class="text-center pos-re">
  <div class="container">
    <div class="row">
      <div class="col-md-4 offset-md-8">
        <p>&copy; 2020 EDGE DESIGN. ALL RIGHT RESERVED.</p>
      </div>
    </div>
  </div>
</footer>
