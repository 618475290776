<!-- Sidebar -->
<div class="togo-fixed-sidebar togo-sidebar-left">
  <div class="togo-header-container">
    <!-- Logo -->
    <div class="logo">
      <h1>
        <a routerLink="index.html">EDGE<span>DESIGN & FURNITURE'S</span></a>
      </h1>
    </div>
    <!-- Burger menu -->
    <div class="togo-burger-menu">
      <div class="togo-line-menu togo-line-half togo-first-line"></div>
      <div class="togo-line-menu"></div>
      <div class="togo-line-menu togo-line-half togo-last-line"></div>
    </div>
    <!-- Navigation menu -->
    <nav class="togo-menu-fixed">
      <ul>
        <li><a routerLink="/" data-scroll-nav="0">Home</a></li>
        <li><a routerLink="/service" data-scroll-nav="1">Services</a></li>
        <li><a routerLink="/about" data-scroll-nav="2">About Us</a></li>
        <li><a routerLink="/projects" data-scroll-nav="3">Projects</a></li>
        <li><a routerLink="/blog" data-scroll-nav="4">News</a></li>
        <li><a routerLink="/contact" data-scroll-nav="5">Contact</a></li>
      </ul>
    </nav>
    <!-- Menu social media -->
    <div class="togo-menu-social-media">
      <div class="social">
        <a routerLink="/"><i class="ti-facebook"></i></a>
        <a routerLink="/"><i class="ti-twitter"></i></a>
        <a routerLink="/"><i class="ti-instagram"></i></a>
      </div>
      <div class="togo-menu-copyright">
        <p>© 2020 <a href="/">EDGE DESIGN</a></p>
      </div>
    </div>
  </div>
</div>
