<!-- Services -->
<section class="services mt-100 pb-100" data-scroll-index="1">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-20 text-center">
        <h6 class="small-title">What We Do</h6>
        <h4 class="title">Our Services</h4>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4 col-12">
        <div class="item bg-1">
          <div class="con">
            <div class="numb">01</div>
            <h5>Architecture</h5>
            <p>
              Architecture bibendum eros eget lacus the vulputate, sit amet
              vehicula nibh placerat in lectus vitae justo pulvinar cursus.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4 col-12">
        <div class="item bg-2">
          <div class="con">
            <div class="numb">02</div>
            <h5>Interior Design</h5>
            <p>
              Interior design eros eget lacus the vulputate, sit amet vehicula
              nibh placerat in lectus vitae the justo pulvinar of cursus.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4 col-12">
        <div class="item bg-3">
          <div class="con">
            <div class="numb">03</div>
            <h5>Urban Design</h5>
            <p>
              Urban design eros eget lacus the vulputate, sit amet vehicula nibh
              placerat in lectus vitae the justo pulvinar of cursus.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
