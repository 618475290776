<!-- Content -->
<edge-sidebar></edge-sidebar>
<div class="togo-side-content">
  <!-- Lines -->
  <div class="content-lines-wrapper d-none">
    <div class="content-lines-inner">
      <div class="content-lines"></div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
