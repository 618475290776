<!-- About -->
<section class="about mt-100 pb-100" data-scroll-index="2">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-20">
        <h6 class="small-title">About Us</h6>
        <h4 class="title">About EDGE DESIGN</h4>
        <p>
          Quisque urna nisi, congue sit amet lorem sit amet, sodales pretium
          risus. Nulla porta eget augue a consequat. Suspendisse auctor faucibus
          tortor quis malesuada. Pellentesque eget nisl non orci finibus
          tincidunt at eu nisl.
        </p>
        <p>
          Nulla porta eget augue a consequat. Suspendisse auctor faucibus tortor
          quis the drana. Pellentesque eget nisl non orci finibus tincidunt at
          eu nisl.
        </p>
        <p>
          Architecture bibendum pharetra eleifend. Suspendisse vel volutpat
          purus, sit amet bibendum nisl. Cras mollis turpis a ipsum ultes, nec
          condimentum ipsum consequat. Mauris vitae consequat nibh, vitae
          interdum mi.
        </p>
      </div>
      <div class="col-md-6 mb-20 image">
        <div class="img">
          <img
            src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/25.png"
            alt=""
          />
          <a class="vid" href="https://www.youtube.com/watch?v=UrkXWG9WgC8">
            <span class="vid-togo-button"><i class="ti-control-play"></i></span>
          </a>
        </div>
      </div>
      <div class="col-md-12">
        <div class="yearimg">
          <div class="numb">10</div>
        </div>
        <div class="year">
          <h6 class="small-title">IN ARCHITECTURE DESING</h6>
          <h4 class="title">Years of Experience</h4>
        </div>
      </div>
    </div>
  </div>
</section>
