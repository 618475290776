<!-- Testimonials -->
<section
  class="testimonial bg-img bg-fixed pos-re mt-100 pt-100 pb-100"
  data-overlay-dark="6"
  data-background="assets/img/slider/3.jpg"
>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="testimonials">
          <span class="icon"
            ><img
              src="http://duruthemes.com/demo/html/togo/onepage-light/assets/img/left-quote.png"
              alt=""
          /></span>
          <div class="owl-carousel owl-theme text-center">
            <div class="item">
              <div class="client-area">
                <h6>Emma Brown</h6>
                <span>Claire Towers, CEO</span>
              </div>
              <p>
                " Vivamus faucibus lorem non mi varius, vel euismod magna
                malesuada. Nam nec diam in nisl porttitor commodo in et ligula.
                "
              </p>
            </div>
            <div class="item">
              <div class="client-area">
                <h6>Jesica White</h6>
                <span>Drana Inc, Manager</span>
              </div>
              <p>
                " Qaulity faucibus lorem non mi varius, vel euismod magna
                malesuada. Nam nec diam in nisl porttitor commodo in et ligula.
                "
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
